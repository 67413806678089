import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
import formatter from "@utils/formatter";
export default {
  name: "boost",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"]),
    ...userVuex.mapState(["userData"]),
    getLevelPrice() {
      return formatter.getLevelPrice;
    },
    toPre() {
      return formatter.toPre;
    }
  },
  data() {
    return {
      popType: "",
      popEnergyShow: false
    };
  },
  beforeRouteLeave(to, from, next) {
    this.popEnergyShow = false;
    this.$nextTick(() => {
      next();
    });
  },
  watch: {
    userData: {
      async handler(newVal, oldVal) {},
      immediate: true,
      deep: true
    }
  },
  methods: {
    onBoo(type) {
      this.popType = type;
      this.popEnergyShow = true;
    }
  }
};