var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "95%",
      "height": "auto",
      "is-loc": "bottom"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "energy__img"
  }, [_vm.getTypeIcon ? _c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/${_vm.getTypeIcon}`),
      "alt": ""
    }
  }) : _vm._e()]), _c('div', {
    staticClass: "energy__title"
  }, [_vm._v(_vm._s(_vm.getTypeTitle))]), _c('div', {
    staticClass: "energy__subtitle"
  }, [_vm.type === 'Multitap' ? [_vm._v("Amount value per coin "), _c('br'), _c('i', [_vm._v("+1")]), _vm._v(" for each level")] : _vm.type === 'Battery' ? [_vm._v("Increase total coin limit "), _c('br'), _c('i', [_vm._v("+500")]), _vm._v(" for the next level")] : _vm.type === 'Recharge' ? [_vm._v("Increase coin fil speed "), _c('br'), _c('i', [_vm._v("+1/sec")]), _vm._v(" for each level")] : _vm.type === 'Bot' ? [_vm._v("The auto bot if not player for "), _c('br'), _c('i', [_vm._v("1 hour")]), _vm._v(", Works for "), _c('i', [_vm._v("12 hours")])] : _vm._e()], 2), _c('div', {
    staticClass: "energy-coin"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _vm.type != 'Turbo' && _vm.type != 'Energy' ? [_vm.type == 'Bot' ? [_c('span', [_vm._v(" " + _vm._s(_vm._f("toThousands")(20000)))])] : [_c('span', [_vm._v(_vm._s(_vm.toPre(_vm.getLevelPrice(_vm.userData[_vm.getTypeField]), 3, true)))]), _vm._v("/Lv" + _vm._s(_vm.userData[_vm.getTypeField]) + " ")]] : [_vm._v(" " + _vm._s(_vm.$lang("Free")) + " ")]], 2), _vm.type == 'Turbo' || _vm.type == 'Energy' ? [_vm.type == 'Turbo' ? [_vm.userData.turbo_count > 0 ? _c('div', {
    staticClass: "btn-level",
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(_vm._s(_vm.$lang("Use")))]) : _c('div', {
    staticClass: "btn-level as"
  }, [_vm._v(_vm._s(_vm.$lang("No times, cannot be used")))])] : _vm._e(), _vm.type == 'Energy' ? [_vm.userData.refill_count > 0 ? _c('div', {
    staticClass: "btn-level",
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(_vm._s(_vm.$lang("Use")))]) : _c('div', {
    staticClass: "btn-level as"
  }, [_vm._v(_vm._s(_vm.$lang("No times, cannot be used")))])] : _vm._e()] : _vm.type != 'Bot' ? [_c('div', {
    staticClass: "btn-level",
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(_vm._s(_vm.$lang("Level Up")))])] : [_vm.userData.auto_bot == 0 ? _c('div', {
    staticClass: "btn-level",
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(_vm._s(_vm.$lang("Enable")))]) : _vm._e()]], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };