var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "indexPage",
    class: _vm.isMobile && 'wap'
  }, [[_c('div', {
    staticClass: "bg",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/index-bg.jpg`)})`
    }
  }), _vm.isShowTurboScreen ? _c('div', {
    staticClass: "turboBg"
  }, _vm._l(2, function (_) {
    return _c('span', {
      style: {
        backgroundImage: `url(${require(`@images/${_vm.theme}/index-icon-small-coin.png`)})`
      }
    });
  }), 0) : _vm._e()], _c('div', {
    staticClass: "top"
  }, [_c('div', {
    staticClass: "header",
    on: {
      "click": function ($event) {
        return _vm.onSkip(_vm.userData.user_group_id <= 0 ? 'join' : 'squad', _vm.userData.user_group_id);
      }
    }
  }, [_c('div', {
    staticClass: "icon",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/index-icon-squad.png`)})`
    }
  }), _c('span', [_vm.userData.user_group_id ? [_vm._v(" " + _vm._s(_vm.userData.user_group_name) + " ")] : [_vm._v(" " + _vm._s(_vm.$lang("JOIN SQUAD")) + " ")]], 2)]), _c('div', {
    staticClass: "total",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return (() => {}).apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "icon",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/index-icon-small-coin.png`)})`
    }
  }), _c('div', {
    staticClass: "total__value",
    class: !_vm.isMobile && 'pc'
  }, [!_vm.isIos ? [_c('CountUp', {
    attrs: {
      "num": _vm.userData.coin
    }
  })] : [_vm._v(_vm._s(_vm._f("toThousands")(_vm.userData.coin)))]], 2)]), _c('div', {
    staticClass: "sub",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return (() => {}).apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "sub-item",
    on: {
      "click": function ($event) {
        return _vm.onSkip('rank');
      }
    }
  }, [_c('div', {
    staticClass: "sub__icon",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/level/${_vm.getLevelName(_vm.userData.user_level)}.png`)})`
    }
  }), _c('div', {
    staticClass: "sub__value bronze"
  }, [_vm._v(_vm._s(_vm._f("getLevelName")(_vm.userData.user_level)))]), _c('div', {
    staticClass: "sub__arrow"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })], 1)])])]), _c('div', {
    staticClass: "center"
  }, [_c('div', {
    staticClass: "coin",
    on: {
      "touchstart": function ($event) {
        _vm.isMobile && _vm.handleClick($event);
      },
      "click": function ($event) {
        !_vm.isMobile && _vm.handleClick($event);
      }
    }
  }, [_c('my-tile', {
    attrs: {
      "max": 15
    }
  }, [_c('div', {
    ref: "coinRef",
    staticClass: "coin-main",
    class: !_vm.isMobile && 'pc',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/index-icon-big-coin.png`)})`
    }
  })])], 1)]), _c('div', {
    staticClass: "bottomX",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return (() => {}).apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "progress"
  }, [_c('div', {
    staticClass: "progress-main"
  }, [_c('div', {
    staticClass: "bar"
  }, [_c('div', {
    staticClass: "bar-main",
    style: {
      left: -100 + Number(_vm.progressBar) + '%'
    }
  })]), _c('div', {
    staticClass: "progress__value"
  }, [_vm._v(" " + _vm._s(_vm.userData.power) + " ")])]), _c('div', {
    staticClass: "progress-icon",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/index-icon-energy.png`)})`
    }
  })]), _c('div', {
    staticClass: "menu"
  }, [_c('div', {
    staticClass: "menu-bg"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/menu-bg.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "menu-main"
  }, [_c('div', {
    staticClass: "menu-item",
    on: {
      "click": function ($event) {
        return _vm.onSkip('earn');
      }
    }
  }, [_c('div', {
    staticClass: "menu-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-btn-earn.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "menu-label"
  }, [_vm._v(_vm._s(_vm.$lang("EARN")))])]), _c('div', {
    staticClass: "menu-item",
    on: {
      "click": function ($event) {
        return _vm.onSkip('frens');
      }
    }
  }, [_c('div', {
    staticClass: "menu-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-btn-frens.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "menu-label"
  }, [_vm._v(_vm._s(_vm.$lang("FRENS")))])]), _c('div', {
    staticClass: "menu-item",
    on: {
      "click": function ($event) {
        return _vm.onSkip('boost');
      }
    }
  }, [_c('div', {
    staticClass: "menu-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-btn-boost.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "menu-label"
  }, [_vm._v(_vm._s(_vm.$lang("BOOSTS")))])])])])]), [_c('div', {
    ref: "blockRef",
    staticClass: "block",
    on: {
      "touchstart": function ($event) {
        _vm.isMobile && _vm.handleClick($event);
      },
      "click": function ($event) {
        !_vm.isMobile && _vm.handleClick($event);
      }
    }
  }, _vm._l(_vm.blockAnimList, function (item, index) {
    return _c('span', {
      key: index,
      class: item.bool && 'active',
      style: {
        left: item.x,
        top: item.y
      }
    }, [_vm._v("+" + _vm._s(_vm.turbo_temp_times <= 0 ? _vm.userData.multi_tap : _vm.userData.multi_tap * _vm.turbo_temp_times))]);
  }), 0)], _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowTurboIcon,
      expression: "isShowTurboIcon"
    }],
    ref: "turboRef",
    staticClass: "turbo",
    on: {
      "touchstart": function ($event) {
        _vm.isMobile && _vm.isShowTurboIcon && _vm.onShowTurboScreen();
      },
      "click": function ($event) {
        $event.stopPropagation();
        !_vm.isMobile && _vm.isShowTurboIcon && _vm.onShowTurboScreen();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/turbo.png`),
      "alt": ""
    }
  })]), _c('pop-robot', {
    attrs: {
      "show": _vm.popRobotShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popRobotShow = $event;
      }
    }
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };