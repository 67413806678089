import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
import noZoom from "./assets/js/noZoom";
noZoom();
export default {
  provide() {
    return {
      reload: this.reload
    };
  },
  computed: {
    ...walletVuex.mapState(["isMobile"]),
    ...userVuex.mapState(["userData"]),
    route() {
      return this.$route.path;
    }
  },
  data() {
    return {
      isLoading: false,
      isRouterAlive: true
    };
  },
  watch: {
    userData: {
      async handler(newVal, oldVal) {
        if (this.userData.id) this.isLoading = true;
      },
      immediate: true,
      deep: true
    },
    "$route.path": {
      handler(newPath, oldPath) {
        console.log("$route.path", newPath, oldPath);
        const BackButton = this.WebApp.BackButton;
        if (newPath === "/") {
          BackButton.isVisible && BackButton.hide();
          return;
        }
        BackButton.show();
        BackButton.onClick(() => {
          if (oldPath !== "/") {
            if (oldPath === "/join") {
              this.$router.push("/");
            } else {
              this.$router.go(-1);
            }
          } else {
            this.$router.push("/");
          }
        });
      },
      deep: true
    }
  },
  methods: {
    ...walletVuex.mapActions(["getSetting"]),
    ...userVuex.mapActions(["login", "getUserData", "powerPlus"]),
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    }
  },
  async mounted() {
    console.log("this.WebApp.initData", this.WebApp);
    await this.getSetting();
    await this.getUserData(this.WebApp.initData);
    this.powerPlus();
  }
};