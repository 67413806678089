import axios from "axios";
import Vue from "vue";
import loadConfig from "@Web3WalletConfig/config";
import store from "@store/index";
import qs from "qs";
import cookie from "js-cookie";

const Axios = axios.create({
  timeout: 100000, // 请求超时时间
});

Axios.defaults.baseURL = loadConfig.http_host;
// 添加请求拦截器
Axios.interceptors.request.use(
  (config) => {
    
    console.log(config.data)
    let token = store.state['user']['token'] || '';
    if (token) {
      config.headers["token"] = "Bearer " + token;
      config.data['_token'] = token;
    }
    config.data = qs.stringify(config.data);
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

/* 响应拦截器 */
Axios.defaults.validateStatus = (status) => {
  // 根据状态码返回结果
  if (/^(2|3)\d{2}$/.test(status)) {
    return true;
  } else {
    console.error(`Error: ${status} error`);
  }
};
// 添加响应拦截器
Axios.interceptors.response.use(
  (res) => {
    // 对响应数据做点什么
    return res.data;
  },
  (err) => {
    /* 没网 */
    if (!window.navigator.onLine) {
      //跳转到断网页面
      Vue.toasted.success(`${Vue.prototype.$lang("Error: Network exception")}`);
      return;
    } else {
      return Promise.reject(err.data);
    }
  }
);

// 对axios的实例重新封装成一个plugin ,方便 Vue.use(xxxx)
export default {
  install: (Vue) =>
    Object.defineProperty(Vue.prototype, "$http", {
      value: Axios,
    }),
  Axios,
};
