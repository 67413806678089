import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const rankingVuex = createNamespacedHelpers("ranking");
import loadConfig from "@Web3WalletConfig/config";
export default {
  name: "frens",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"]),
    ...userVuex.mapState(["userData"]),
    ...rankingVuex.mapState(["firendList"])
  },
  data() {
    return {
      loadConfig,
      popShareShow: false
    };
  },
  mounted() {
    this.getFirendList();
  },
  beforeRouteLeave(to, from, next) {
    this.popShareShow = false;
    this.$nextTick(() => {
      next();
    });
  },
  methods: {
    ...rankingVuex.mapActions(["getFirendList"]),
    onBtnSend() {
      //this.WebApp.openTelegramLink(`https://t.me/${this.loadConfig.bot_name}?start=referral`);
      this.popShareShow = true;
    }
  }
};