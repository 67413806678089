import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "earn",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {
      popUpGoldShow: false,
      popCoinHeroShow: false
    };
  },
  mounted() {},
  methods: {
    ...userVuex.mapMutations(['setUserData']),
    onUpGold() {
      this.popUpGoldShow = true;
    },
    onCoinHero() {
      this.popCoinHeroShow = true;
    },
    async onJoin(type, url) {
      console.log("url=====", url);
      if (type == 'telegram' || type == 'channel') {
        this.WebApp.openTelegramLink(url);
      } else {
        this.WebApp.openLink(url);
      }
      let rs = await this.$http.post("/my/social/join", {
        target: type
      });
      if (rs.code == 0) {
        let newUserData = {
          ...this.userData,
          ...(rs.data.userInfo ? rs.data.userInfo : rs.data)
        };
        this.setUserData(newUserData);
      }
      // this.$loading.show();
      // setTimeout(()=>{
      //   this.$loading.hide();
      // },1000)
    }
  }
};