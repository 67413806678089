var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "boost page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/inset-bg.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "boost-main"
  }, [_c('div', {
    staticClass: "balance"
  }, [_c('div', {
    staticClass: "balance__title"
  }, [_vm._v(_vm._s(_vm.$lang("Your balance")))]), _c('div', {
    staticClass: "balance-main"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "balance__num"
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.userData.coin)))])])]), _c('div', {
    staticClass: "free"
  }, [_c('div', {
    staticClass: "free__title"
  }, [_vm._v(_vm._s(_vm.$lang("Free daily boosters")))]), _c('div', {
    staticClass: "free-main"
  }, [_c('div', {
    staticClass: "free-item",
    on: {
      "click": function ($event) {
        return _vm.onBoo('Turbo');
      }
    }
  }, [_c('div', {
    staticClass: "free__title"
  }, [_vm._v(_vm._s(_vm.$lang("Turbo")))]), _c('div', {
    staticClass: "free__label"
  }, [_vm._v(_vm._s(_vm.userData.turbo_count) + "/" + _vm._s(_vm.userData.turbo_max) + " " + _vm._s(_vm.$lang("available")))]), _c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/boost-list-icon-1.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "free-item",
    on: {
      "click": function ($event) {
        return _vm.onBoo('Energy');
      }
    }
  }, [_c('div', {
    staticClass: "free__title"
  }, [_vm._v(_vm._s(_vm.$lang("Full Energy")))]), _c('div', {
    staticClass: "free__label"
  }, [_vm._v(_vm._s(_vm.userData.refill_count) + "/" + _vm._s(_vm.userData.refill_max) + " " + _vm._s(_vm.$lang("available")))]), _c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/boost-list-icon-2.png`),
      "alt": ""
    }
  })])])]), _c('div', {
    staticClass: "boo"
  }, [_c('div', {
    staticClass: "boo__title"
  }, [_vm._v(_vm._s(_vm.$lang("Boosters")))]), _c('div', {
    staticClass: "boo-list"
  }, [_c('div', {
    staticClass: "boo-item",
    on: {
      "click": function ($event) {
        return _vm.onBoo('Multitap');
      }
    }
  }, [_c('div', {
    staticClass: "boo-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/boost-list-icon-3.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "boo-info"
  }, [_c('div', {
    staticClass: "boo__title"
  }, [_vm._v(_vm._s(_vm.$lang("Multitap")))]), _c('div', {
    staticClass: "boo__num"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.toPre(_vm.getLevelPrice(_vm.userData.multi_tap_level), 3, true)) + " "), _c('span', [_vm._v("· " + _vm._s(_vm.userData.multi_tap_level) + " lvl")])])]), _c('div', {
    staticClass: "boo-arrow"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })], 1)]), _c('div', {
    staticClass: "boo-item",
    on: {
      "click": function ($event) {
        return _vm.onBoo('Battery');
      }
    }
  }, [_c('div', {
    staticClass: "boo-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/boost-list-icon-4.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "boo-info"
  }, [_c('div', {
    staticClass: "boo__title"
  }, [_vm._v(_vm._s(_vm.$lang("Battery Pack")))]), _c('div', {
    staticClass: "boo__num"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.toPre(_vm.getLevelPrice(_vm.userData.power_level), 3, true)) + " "), _c('span', [_vm._v("· " + _vm._s(_vm.userData.power_level) + " lvl")])])]), _c('div', {
    staticClass: "boo-arrow"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })], 1)]), _c('div', {
    staticClass: "boo-item",
    on: {
      "click": function ($event) {
        return _vm.onBoo('Recharge');
      }
    }
  }, [_c('div', {
    staticClass: "boo-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/boost-list-icon-5.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "boo-info"
  }, [_c('div', {
    staticClass: "boo__title"
  }, [_vm._v(_vm._s(_vm.$lang("Recharge Speed")))]), _c('div', {
    staticClass: "boo__num"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.toPre(_vm.getLevelPrice(_vm.userData.recharge_speed_level), 0, true)) + " "), _c('span', [_vm._v("· " + _vm._s(_vm.userData.recharge_speed_level) + " lvl")])])]), _c('div', {
    staticClass: "boo-arrow"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })], 1)]), _c('div', {
    staticClass: "boo-item",
    on: {
      "click": function ($event) {
        return _vm.onBoo('Bot');
      }
    }
  }, [_c('div', {
    staticClass: "boo-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/boost-list-icon-6.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "boo-info"
  }, [_c('div', {
    staticClass: "boo__title"
  }, [_vm._v(_vm._s(_vm.$lang("Auto Bot")))]), _c('div', {
    staticClass: "boo__num"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm._f("toPre")(20000, 3, true)) + " ")])]), _c('div', {
    staticClass: "boo-arrow",
    class: _vm.userData.auto_bot == 1 && 'active'
  }, [_vm.userData.auto_bot == 0 ? [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })] : _vm.userData.auto_bot == 1 ? [_c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  })] : _vm._e()], 2)])])])]), _c('pop-energy', {
    attrs: {
      "show": _vm.popEnergyShow,
      "type": _vm.popType
    },
    on: {
      "update:show": function ($event) {
        _vm.popEnergyShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };