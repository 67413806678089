var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "frens page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/inset-bg.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "frens-main"
  }, [_c('div', {
    staticClass: "total"
  }, [_c('div', {
    staticClass: "total__title"
  }, [_vm._v(_vm._s(_vm.$lang("Total rewards")))]), _c('div', {
    staticClass: "total__num"
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.userData.total_reward)))]), _c('div', {
    staticClass: "total__img",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/earn-icon-0.png`)})`
    }
  })]), _c('div', {
    staticClass: "invite"
  }, [_c('div', {
    staticClass: "invite__title"
  }, [_vm._v(_vm._s(_vm.$lang("Invite frens to get bonuses")))]), _c('div', {
    staticClass: "invite-main"
  }, [_c('div', {
    staticClass: "invite-item"
  }, [_c('div', {
    staticClass: "invite__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "invite_info"
  }, [_c('div', {
    staticClass: "invite__label",
    domProps: {
      "innerHTML": _vm._s(_vm.$lang(`Invite Fren`))
    }
  }), _c('div', {
    staticClass: "invite__brief",
    domProps: {
      "innerHTML": _vm._s(_vm.$lang(`<i>2.5K</i> for you and fren`))
    }
  })])]), _c('div', {
    staticClass: "invite-item"
  }, [_c('div', {
    staticClass: "invite__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/frens-3.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "invite_info"
  }, [_c('div', {
    staticClass: "invite__label",
    domProps: {
      "innerHTML": _vm._s(_vm.$lang(`Fren with Telegram Preminum`))
    }
  }), _c('div', {
    staticClass: "invite__brief",
    domProps: {
      "innerHTML": _vm._s(_vm.$lang(`<i>50K</i> for you and fren`))
    }
  })])])])]), _vm.firendList.length > 0 ? _c('div', {
    staticClass: "fs"
  }, [_c('div', {
    staticClass: "fs__title"
  }, [_vm._v(_vm._s(_vm.$lang("Frens List")))]), _c('div', {
    staticClass: "fs-list"
  }, _vm._l(_vm.firendList, function (item, index) {
    return _c('div', {
      staticClass: "fs-item"
    }, [_c('m-head', {
      attrs: {
        "name": item,
        "bgColor": item.head_color
      }
    }), _c('div', {
      staticClass: "item-info"
    }, [_c('div', {
      staticClass: "item__label"
    }, [_vm._v(_vm._s(item.firstname) + " " + _vm._s(item.lastname))]), _c('div', {
      staticClass: "item__num"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm._f("toThousands")(item.coin)) + " ")])])], 1);
  }), 0)]) : _vm._e()]), _c('div', {
    staticClass: "btn-send",
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    on: {
      "click": _vm.onBtnSend
    }
  }, [_vm._v(_vm._s(_vm.$lang("Send to invite")))]), _c('pop-share', {
    attrs: {
      "show": _vm.popShareShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popShareShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };