import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
import loadConfig from "@Web3WalletConfig/config";
export default {
  name: "popShare",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {
      loadConfig
    };
  },
  methods: {
    onShare() {
      this.WebApp.openTelegramLink(`https://t.me/share/url?text=%F0%9F%8E%81%20+2.5k%20${this.setting.coin}%20as%20a%20first-time%20gift%0A%F0%9F%8E%81%F0%9F%8E%81%F0%9F%8E%81%20+50k%20${this.setting.coin}%20if%20you%20have%20Telegram%20Premium%20&url=https://t.me/${this.loadConfig.bot_name}?start=ref_${this.userData.ref_code}`);
    }
  }
};