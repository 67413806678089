var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "earn page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/inset-bg.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "earn-main"
  }, [_c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "head-img",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/earn-head-icon-gift.png`)})`
    }
  }), _c('div', {
    staticClass: "head__title"
  }, [_vm._v(_vm._s(_vm.$lang("Earn More")))])]), _c('div', {
    staticClass: "invite"
  }, [_c('div', {
    staticClass: "invite__title"
  }, [_vm._v(_vm._s(_vm.$lang("Explore")))]), _c('div', {
    staticClass: "invite-list"
  }, [_vm.setting.socials.discord ? _c('div', {
    staticClass: "invite-item",
    class: _vm.userData.joined_discord == 1 && 'active',
    on: {
      "click": function ($event) {
        return _vm.onJoin('discord', _vm.setting.socials.discord);
      }
    }
  }, [_c('div', {
    staticClass: "invite-icon"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-discord"
    }
  })], 1), _c('div', {
    staticClass: "invite-info"
  }, [_c('div', {
    staticClass: "invite__title"
  }, [_vm._v(_vm._s(_vm.$lang("Join discrod")))]), _c('div', {
    staticClass: "invite__num"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm._f("toThousands")(_vm.setting.reward.joined.discord)) + " ")])]), _c('div', {
    staticClass: "invite-arrow"
  }, [_vm.userData.joined_discord == 0 ? [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })] : _vm.userData.joined_discord == 1 ? [_c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  })] : _vm._e()], 2)]) : _vm._e(), _vm.setting.socials.channel ? _c('div', {
    staticClass: "invite-item",
    class: _vm.userData.joined_channel == 1 && 'active',
    on: {
      "click": function ($event) {
        return _vm.onJoin('channel', _vm.setting.socials.channel);
      }
    }
  }, [_c('div', {
    staticClass: "invite-icon"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-telegram"
    }
  })], 1), _c('div', {
    staticClass: "invite-info"
  }, [_c('div', {
    staticClass: "invite__title"
  }, [_vm._v(_vm._s(_vm.$lang("Join channel")))]), _c('div', {
    staticClass: "invite__num"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm._f("toThousands")(_vm.setting.reward.joined.channel)) + " ")])]), _c('div', {
    staticClass: "invite-arrow"
  }, [_vm.userData.joined_channel == 0 ? [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })] : _vm.userData.joined_channel == 1 ? [_c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  })] : _vm._e()], 2)]) : _vm._e(), _vm.setting.socials.twitter ? _c('div', {
    staticClass: "invite-item",
    class: _vm.userData.joined_twitter == 1 && 'active',
    on: {
      "click": function ($event) {
        return _vm.onJoin('twitter', _vm.setting.socials.twitter);
      }
    }
  }, [_c('div', {
    staticClass: "invite-icon"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-lfg"
    }
  })], 1), _c('div', {
    staticClass: "invite-info"
  }, [_c('div', {
    staticClass: "invite__title"
  }, [_vm._v(_vm._s(_vm.$lang("Follow us on X")))]), _c('div', {
    staticClass: "invite__num"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm._f("toThousands")(_vm.setting.reward.joined.twitter)) + " ")])]), _c('div', {
    staticClass: "invite-arrow"
  }, [_vm.userData.joined_twitter == 0 ? [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })] : _vm.userData.joined_twitter == 1 ? [_c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  })] : _vm._e()], 2)]) : _vm._e(), _vm.setting.socials.telegram ? _c('div', {
    staticClass: "invite-item",
    class: _vm.userData.joined_telegram == 1 && 'active',
    on: {
      "click": function ($event) {
        return _vm.onJoin('telegram', _vm.setting.socials.telegram);
      }
    }
  }, [_c('div', {
    staticClass: "invite-icon"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-telegram"
    }
  })], 1), _c('div', {
    staticClass: "invite-info"
  }, [_c('div', {
    staticClass: "invite__title"
  }, [_vm._v(_vm._s(_vm.$lang("Join group")))]), _c('div', {
    staticClass: "invite__num"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm._f("toThousands")(_vm.setting.reward.joined.telegram)) + " ")])]), _c('div', {
    staticClass: "invite-arrow"
  }, [_vm.userData.joined_telegram == 0 ? [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })] : _vm.userData.joined_telegram == 1 ? [_c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  })] : _vm._e()], 2)]) : _vm._e()])])]), _c('pop-up-gold', {
    attrs: {
      "show": _vm.popUpGoldShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popUpGoldShow = $event;
      }
    }
  }), _c('pop-coin-hero', {
    attrs: {
      "show": _vm.popCoinHeroShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popCoinHeroShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };