var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "height": "auto",
      "width": "80%"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$lang("Share invite link")))]), _c('div', {
    staticClass: "btn-share",
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    on: {
      "click": _vm.onShare
    }
  }, [_vm._v(_vm._s(_vm.$lang("Share")))]), _c('div', {
    staticClass: "code"
  }, [_c('div', {
    staticClass: "code__title"
  }, [_vm._v(_vm._s(_vm.$lang("secret code to invite")))]), _c('div', {
    staticClass: "code__value"
  }, [_vm._v(_vm._s(_vm.userData.ref_code))]), _c('div', {
    directives: [{
      name: "copy",
      rawName: "v-copy",
      value: `https://t.me/${_vm.loadConfig.bot_name}?start=ref_${_vm.userData.ref_code}`,
      expression: "`https://t.me/${loadConfig.bot_name}?start=ref_${userData.ref_code}`"
    }],
    staticClass: "btn-copy"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-copy"
    }
  }), _vm._v(" " + _vm._s(_vm.$lang("Copy")) + " ")], 1)]), _c('div', {
    staticClass: "info"
  }, [_vm._v(_vm._s(_vm.$lang("Recormend if you shared to groups that blocks links")))])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };