var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "95%",
      "height": "auto",
      "is-loc": "bottom"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "energy__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/boost-list-icon-6.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "energy__intro"
  }, [_vm._v(" " + _vm._s(_vm.$lang("The Robot collected")) + " "), _c('br'), _c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _c('span', [_vm._v(_vm._s(_vm._f("toThousands")(_vm.robotBox.coin)))])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };