import Vue from "vue";
import vailcode from "@utils/errcode";
import cookie from "js-cookie";
import querystring from "querystring";

export default {
  namespaced: true,
  state: {
    token:'',
    userData: {},
    totalCoin: 0,
    isICountUp: true,
    robotBox: {
      robot: false,
      coin: 0,
    },
    turbo_temp_times: 0,
  },
  mutations: {
    setToken: (state, payload)=> {
      state.token = payload
    },
    setTurboTempTimes: (state, payload)=> {
      state.turbo_temp_times = payload
    },
    setRobotBox: (state, payload) => {
      for (const key in state.robotBox) {
        Vue.prototype.$set(state.robotBox, key, payload[key]);
      }
    },
    setIsICountUp: (state, payload) => {
      state.isICountUp = payload;
    },
    setUserData: (state, payload) => {
      for (const key in payload) {
        Vue.prototype.$set(state.userData, key, payload[key]);
      }
    },
    setTotalCoin: (state, payload) => (state.totalCoin = payload),
  },
  actions: {
    login: async ({ state, commit, dispatch }, [data, callback]) => {
      const rs = await Vue.prototype.$http.post("/auth/login", { initData: data });
      vailcode(rs, async () => {
        console.log(rs.data);
        //localStorage.setItem("token", rs.data);
        //cookie.set("token", rs.data, { expires: 15 });
        commit('setToken',rs.data);
       
        typeof callback == "function" && callback(rs.data);
      });
    },
    getUserData: async ({ state, commit, dispatch }, data) => {
      if (!state.token && data) {
        await dispatch("login", [data, null]);
      }
      const rs = await Vue.prototype.$http.post("/coin/collect", { coin: 0 });
      vailcode(
        rs,
        () => {
          commit("setUserData", rs.data.userInfo);
          commit("setRobotBox", {
            robot: rs.data.robot,
            coin: rs.data.coin,
          });
        },
        async() => {
          if (data) {
            await dispatch("login", [data, null])
            await dispatch("getUserData", null);
          };
        }
      );
    },
    powerPlus: ({ state, commit, dispatch }) => {
      setTimeout(() => {
        const userData = state.userData;
        let power = userData.power + userData.recharge_speed;
        if (power > userData.power_max) {
          power = userData.power_max;
        }
        let newInfo = state.userData;
        newInfo.power = power;
        commit("setUserData", newInfo);
        dispatch("powerPlus");
      }, 1000);
    },
  },
  getters: {},
};
